<template>
  <div class="on">
    <div class="nav">
      <div class="logo">
        <img src="../../assets/image/logonew.png" alt="">
      </div>
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" router
        style="border-bottom: none;" background-color="#fff" text-color="#000" active-text-color="#e84554">
        <el-menu-item class="menu" index="index">首页</el-menu-item>
        <el-menu-item class="menu" index="product" @mouseover.native="showis" @mouseout.native="hideis">产品中心
        </el-menu-item>
        <el-menu-item class="menu" index="news">解决方案</el-menu-item>
        <!-- <el-menu-item class="menu" index="successfulCase" disabled> 经典案例</el-menu-item>
        <el-menu-item class="menu" index="funct" disabled>版本&功能</el-menu-item>
        <el-menu-item class="menu" index="about" disabled>关于我们</el-menu-item> -->
      </el-menu>
      <div class="poto">
        <!-- <img src="../../assets/image/to2.png" alt=""> -->
      </div>
      <!-- <div class="box" @mouseover.native="showis" @mouseout="hideis"></div> -->
    </div>
    <!-- 产品中心下拉列表 -->
    <div class="prod" v-show="ens" @mouseover="showis" @mouseout="hideis">
      <div class="don">
        <div class="left">
          <h2>产品模块</h2>
          <ul>
            <li class="tolist" @click="tocp('1')">
              <i class="iconfont icon-hulianwang"></i>
              <span>营销拓客</span>
              <p>低成本智能拓客</p>
            </li>
            <li class="tolist" @click="tocp('6')">
              <i class="iconfont icon-yuyueguanli"></i>
              <span>预约管理</span>
              <p style="width:340px">公众号一键在线预约、员工APP预约、智能匹配美容师</p>
            </li>
            <li class="tolist" @click="tocp('2')">
              <i class="iconfont icon-tubiao_gukeguanli"></i>
              <span>顾客管理</span>
              <p>更懂美业顾问管理系统</p>
            </li>
            <li class="tolist" @click="tocp('7')">
              <i class="iconfont icon-shouyinguanli"></i>
              <span>收银管理</span>
              <p>简洁专业的智能美业收银系统</p>
            </li>
            <li class="tolist" @click="tocp('3')">
              <i class="iconfont icon-gukeguanli1"></i>
              <span>员工管理</span>
              <p>效率便捷的员工管理系统</p>
            </li>
            <li class="tolist" @click="tocp('8')">
              <i class="iconfont icon-cangkuguanli"></i>
              <span>仓库管理</span>
              <p>能省钱省人力的库存管理系统</p>
            </li>
            <li class="tolist" @click="tocp('4')">
              <i class="iconfont icon-xiaodian"></i>
              <span>单店会议</span>
              <p>店价值提升系统</p>
            </li>
            <li class="tolist" @click="tocp('9')">
              <i class="iconfont icon-liansuojiameng"></i>
              <span>连锁管理</span>
              <p>多店连锁管理流程化标准化</p>
            </li>
            <li class="tolist" @click="tocp('5')">
              <i class="iconfont icon-xiangmuguanli"></i>
              <span>项目/客装管理</span>
              <p>规范化专业化的项目体系</p>
            </li>
            <li class="tolist" @click="tocp('10')">
              <i class="iconfont icon-shujufenxi"></i>
              <span>数据分析</span>
              <p>近千张报表满足你的使用需求</p>
            </li>
          </ul>
        </div>
        <div class="rifht">
          <img src="../../assets/image/cpxl.png" alt="">
          <h2>立即注册美业汇</h2>
          <h2>免费预约工程师在线演示</h2>
          <!-- <el-button @click="open()">免费试用</el-button> -->
        </div>
      </div>
    </div>
    <!-- 验证 -->
    <el-dialog title="服务热线：136-0200-1885" :visible.sync="centerDialogVisible" width="800px" class="islog">
      <p></p>
      <h2>免费试用</h2>
      <h3>请准确填写您的信息，方便安排专业顾问和您联系！</h3>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="您的姓名" prop="realname">
          <el-input v-model="ruleForm.realname" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input class="isdesc" v-model="ruleForm.code" placeholder="请输入验证码"></el-input>
          <el-button type="primary" @click="submit" class="paics">发送验证码</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'b-nav',
  props: ['activeIndex'],
  data () {
    return {
      ens: false,
      centerDialogVisible: false,
      ruleForm: {
        realname: '',
        mobile: '',
        code: ''
      },
      rules: {
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },

        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      }
    };
  },
  methods: {
    handleSelect (key, keyPath) {
      console.log(key, keyPath);
    },
    showis () {
      this.ens = true
    },
    hideis () {
      // setTimeout()
      this.ens = false
    },
    tocp (val) {
      this.$router.push({
        path: '/product',
        query: {
          val: val
        }
      })
      this.$emit('add', val)
      this.ens = false
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('发送成功');
          this.sub()
        }
      });
    },
    open () {
      this.centerDialogVisible = true
      this.ruleForm.mobile = this.input
    },
    sub () {
      var data = this.ruleForm
      console.log(data);

      this.$http.submit(data).then(res => {
        this.$message({
          message: res,
          type: 'success'
        });
        this.centerDialogVisible = false
      })

    },
    submit () {
      var data = {}
      data.mobile = this.ruleForm.mobile
      this.$http.feilogin(data).then(res => {
        this.$message({
          message: res,
          type: 'success'
        });
      })
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/nav.scss";
</style>